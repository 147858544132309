module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ISS beta key","short_name":"ISS beta","start_url":"/","background_color":"#c53030","theme_color":"#c53030","display":"minimal-ui","icon":"src/images/favicon-32x32.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"http://192.168.0.14:4000"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
